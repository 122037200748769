import { createRouter, createWebHashHistory } from "vue-router";
import { useLabelStore } from "../stores/label";
const Home = () => import("../views/home");
const DashBoard = () => import("../views/wIndexManage/dashBoard");//首页管理
const Banner = () => import("../views/wIndexManage/banner");//banner管理
const Live = () => import("../views/wIndexManage/live");//直播管理
const ContentLive = () => import("../views/wContentManage/live");//内容管理-直播管理
const Content = () => import("../views/wContentManage/content");//内容管理-文章管理
const Goods = () => import("../views/wGoodsManage/goods");//商品管理-商品管理
const Order = () => import("../views/wGoodsManage/content");//商品管理-订单管理
const RegistAudit = () => import("../views/wRegistAudit/goods");//注册审核管理
const ProjectManage = () => import("../views/wProjectManage/project");//项目管理
const TaskA = () => import("../views/wProjectManage/taskA");//项目管理 任务管理
const TaskB = () => import("../views/wProjectManage/taskB");//项目管理 任务下发
const TaskC = () => import("../views/wProjectManage/taskC");//项目管理 任务提交
const TaskD = () => import("../views/wProjectManage/taskD");//项目管理 任务审核
const Module = () => import("../views/wPactManage/module");//合同管理 模版管理
const Pact = () => import("../views/wPactManage/pact");//合同管理 合同生成
const Hospital = () => import("../views/wInfoManage/hospital");//基础信息管理 医院信息管理
const Section = () => import("../views/wInfoManage/section");//基础信息管理 科室信息管理
const Rank = () => import("../views/wInfoManage/rank");//基础信息管理 职称管理
const Pool = () => import("../views/wInfoManage/pool");//基础信息管理 疾病库管理
const Regist = () => import("../views/wDataSelect/regist");//数据查询 注册医生查询
const DoctorSign = () => import("../views/wDataSelect/doctorSign");//数据查询 医生签约
const Task = () => import("../views/wDataSelect/task");//数据查询 任务执行
const Essay = () => import("../views/wDataSelect/essay");//数据查询 文章阅读
const EssayDate = () => import("../views/wDataManage/essay");//数据管理
const Account = () => import("../views/wSystem/account");//系统设置 开账号
const Menu = () => import("../views/wSystem/menu");//系统设置 菜单权限
const Private = () => import("../views/wSystem/private");//系统设置 隐私协议
const Service = () => import("../views/wSystem/service");//系统设置 服务条款
const Feedback = () => import("../views/wSystem/feedback");//系统设置 用户反馈

const NotFound = () => import("../views/notFound");
const Login = () => import("../views/login");
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/home",
      component: Home,
      redirect: "/dashboard",
      children: [
        {//首页管理
          path: "/dashBoard",
          component: DashBoard,
        },
        {//首页 banner管理
          path: "/banner",
          component: Banner,
        },
        {//首页 直播管理
          path: "/live",
          component: Live,
        },
        {//内容 直播管理
          path: "/contentLive",
          component: ContentLive,
        },
        {//内容 文章管理
          path: "/Content",
          component: Content,
        },
        {//商品 商品管理
          path: "/Goods",
          component: Goods,
        },
        {//商品 订单管理
          path: "/Order",
          component: Order,
        },
        {//注册审核管理
          path: "/RegistAudit",
          component: RegistAudit,
        },
        {//项目管理
          path: "/ProjectManage",
          component: ProjectManage,
        },
        {//项目管理 任务管理
          path: "/TaskA",
          component: TaskA,
        },
        {//项目管理 任务下发
          path: "/TaskB",
          component: TaskB,
        },
        {//项目管理 任务提交
          path: "/TaskC",
          component: TaskC,
        },
        {//项目管理 任务审核
          path: "/TaskD",
          component: TaskD,
        },
        {//项目管理 模版审核
          path: "/Module",
          component: Module,
        },
        {//项目管理 合同生成
          path: "/Pact",
          component: Pact,
        },
        {//基础信息管理 医院信息管理
          path: "/Hospital",
          component: Hospital,
        },
        {//基础信息管理 科室信息管理
          path: "/Section",
          component: Section,
        },
        {//基础信息管理 职称管理
          path: "/Rank",
          component: Rank,
        },
        {//基础信息管理 疾病库管理
          path: "/Pool",
          component: Pool,
        },
        {//数据查询 注册医生查询
          path: "/Regist",
          component: Regist,
        },
        {//数据查询 医生签约
          path: "/DoctorSign",
          component: DoctorSign,
        },
        {//数据查询 任务执行
          path: "/Task",
          component: Task,
        },
        {//数据查询 文章阅读
          path: "/Essay",
          component: Essay,
        },
        {//数据查询 文章阅读
          path: "/EssayDate",
          component: EssayDate,
        },
        {//系统设置 开账号
          path: "/Account",
          component: Account,
        },
        {//系统设置 菜单权限
          path: "/Menu",
          component: Menu,
        },
        {//系统设置 隐私协议
          path: "/Private",
          component: Private,
        },
        {//系统设置 服务条款
          path: "/Service",
          component: Service,
        },
        {//系统设置 用户反馈
          path: "/Feedback",
          component: Feedback,
        },
        {//首页管理
          path: "/",
          redirect: "/dashboard",
        },
      ],
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/:pathMatch(.*)*",
      component: NotFound,
    },
  ],
});
router.beforeEach((to, from, next) => {
  // return false
  const label = useLabelStore();
  label.addAction(to.path)
  next()
})
export default router