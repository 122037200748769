<template>

  <RouterView />
</template>

<script setup>
import { RouterView } from "vue-router";
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;

  .common-layout {
    height: 100%;
    //height: calc(100% - 88px) !important;

    .el-container {
      height: 100%;
    }
  }
  .hand {
    //鼠标指针变成手
    cursor: pointer;
  }
}

.el-header {
  --el-header-padding: 0 !important;
  --el-header-height: 88px !important;
}

.el-main {
  --el-main-padding: 0px !important;
}
.el-upload--picture-card{
  margin-left:10px;
  border:none!important;
  background-color:#fff!important;
  --el-upload-picture-card-size:100px!important;
}
.el-upload-list--picture-card{
  display: flex;
  align-items: center;
}
.el-select--large .el-select__wrapper{
  min-height: 34px;
}
.imgSta{
  position:relative;
  margin:0 10px;
  .mb{
    position:absolute;
    top:-5px;
    right:-5px;
    color:#fff;
    width:20px;
    height:20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background:rgba(0,0,0,0.5);
  }
}
</style>
