import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

const pinia = createPinia();
/* import the fontawesome core */
import { library, config } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-regular-svg-icons";

/* add icons to the library */
library.add(faChartLine);
library.add(faInbox);
library.add(faEnvelopeOpenText);
library.add(faCashRegister);
library.add(faUserGear);
library.add(faListCheck);
library.add(faAddressBook);
/* set the default style to something other than classic solid */
config.familyDefault = "classic";
config.styleDefault = "duotone";
import Particles from "@tsparticles/vue3";
import { loadFull } from "tsparticles";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(Particles, {
  init: async (engine) => {
    await loadFull(engine);
  },
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(pinia);
app.use(router).use(ElementPlus).mount(document.getElementById("app"));
