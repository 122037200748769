import { defineStore } from 'pinia'
import router from '../router'
export const useLabelStore = defineStore('label', {
  state: () => ({//store状态，label保存当前标签路径 title保存当前标签标题 currentTag保存当前选中标签 lastTag保存最后访问标签,用于处理标签切换 labelMatch保存路径与标签标题匹配关系。
    label: [],
    title: [],
    currentTag: { value: '' },
    lastTag: [],
    labelMatch: {
      "/dashboard": "数据总览",
      "/upstream": "上游-供应商",
      "/downstream": "下游-客户",
      "/logistics": "物流-第三方承运",
      "/target": "目标-待开发客户",
      "/message": "网站留言区",
      "/goodsname": "货物名称",
      "/goodscharact": "货物特性",
      "/purchasedata": "采购数据",
      "/saledata": "销售数据",
      "/dispatchdata": "调度数据",
      "/financedata": "财务数据",
      "/managerdata": "总经理/管理员数据",
      "/reconciliation/0": "销售对账单",
      "/reconciliation/1": "采购对账单",
      "/reconciliation/2": "运输对账单",
      "/reconciliation/3": "销售提成明细表",
      "/reconciliation/4": "采购提成明细表",
      "/reconciliation/5": "调度提成明细表",
      "/reconciliation/6": "采购服务费明细表",
      "/reconciliation/7": "销售服务费明细表",
      "/reconciliation/8": "危险化学品（采购）台账",
      "/reconciliation/9": "危险化学品（销售）台账",
      "/identity": "人员管理",
      "/statement/0": "总台账表",
      "/statement/1": "客户报表",
      "/statement/2": "供应商报表",
      "/statement/3": "销售人员报表",
      "/statement/4": "采购人员报表",
      "/statement/5": "调度人员报表",
    }
  }),
  getters: {
    // showlabel: (state) => state.show,
  },
  actions: {//定义了多个操作方法来处理标签的添加、删除和切换。
    addAction(route) {//添加标签，如果标签不存在，则将其添加到label和title中，并更新 currentTag 和 lastTag。
      this.currentTag.value = this.labelMatch[route]
      if (!this.label.includes(route)) {
        this.title.push(this.labelMatch[route])
        this.label.push(route)
      }
      const index = this.label.indexOf(route)
      const lastTagIndex = this.lastTag.indexOf(this.title[index])
      if (lastTagIndex > -1) {
        this.lastTag.splice(lastTagIndex, 1)
      }
      this.lastTag.push(this.title[index])
    },
    delAction(title) {//删除标签。找到标签在title和label中的索引，并删除相应的项，同时切换到最后一个访问的标签。
      let switchTitle;
      const index = this.title.indexOf(title)
      const lastTagIndex = this.lastTag.indexOf(title)
      if (this.lastTag.length > 1) {
        if (lastTagIndex === this.lastTag.length - 1) {
          switchTitle = this.lastTag[this.lastTag.length - 2]
        } else {
          switchTitle = this.lastTag[this.lastTag.length - 1]
        }
      } else {
        switchTitle = '数据总览'
      }
      this.lastTag.splice(lastTagIndex, 1)
      if (index > -1) {
        this.title.splice(index, 1)
        this.label.splice(index, 1)
      }
      this.switchAction(switchTitle)
    },
    switchAction(title) {//根据标题切换到相应的路径。
      let path;
      for (let key in this.labelMatch) {
        if (this.labelMatch[key] === title) {
          path = key
        }
      }
      path && router.push({ path })
    }
  },
})